import { useQuery } from 'react-query';

import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { LeadFilter } from 'src/api/playbooks-api';

/**
 * Supported playbook variables.
 * Any variable that is not in this list will be ignored.
 */
export enum PlaybookVariable {
    LeadFirstName = 'lead_first_name',
    PropertyAddress = 'property_address',
    PropertyTourLink = 'property_tour_link',
    PropertyApplicationLink = 'property_application_link',
}

/**
 * All currently supported playbook variables by the frontend
 * The purpose of this constant is to prevent a disagreement between the backend and frontend as to what is supported.
 */
export const VALID_PLAYBOOK_VARIABLES = Object.values(PlaybookVariable);

export type Variable = PlaybookVariable;

type Response = {
    supported_variables: Variable[];
};

const PATH = 'api/landlord/automations/supported-text-template-variables';

export const usePlaybookVariables = () => {
    return useQuery<Variable[]>(
        [queryKeys.playbookTextTemplateVariables],
        async () => {
            const response = await apiClient(PATH).json<Response>();

            const allBackendSupportedVariables = response.supported_variables;
            const supportedVariables = response.supported_variables
                .filter((variable) => VALID_PLAYBOOK_VARIABLES.includes(variable));

            for (const variable of allBackendSupportedVariables) {
                if (!supportedVariables.includes(variable)) {
                    console.warn(`Unsupported playbook variable: ${variable}`);
                }
            }

            return supportedVariables;
        },
    );
};

export const PROPERTY_VARIABLES = [
    PlaybookVariable.PropertyAddress,
    PlaybookVariable.PropertyTourLink,
    PlaybookVariable.PropertyApplicationLink,
];

/**
 * Mapping from variable names to required filters
 * TODO move this to the backend
 */
export const FILTER_DEPS: Record<PlaybookVariable, LeadFilter[]> = {
    [PlaybookVariable.LeadFirstName]: [],
    [PlaybookVariable.PropertyAddress]: [LeadFilter.HasProperty],
    [PlaybookVariable.PropertyTourLink]: [LeadFilter.HasProperty, LeadFilter.HasPropertyTourLink],
    [PlaybookVariable.PropertyApplicationLink]: [LeadFilter.HasProperty, LeadFilter.HasPropertyApplicationLink],
};

/**
 * Depending on the variable names present, return the lead filters that are necessary to apply
 */
export const getRequiredLeadFiltersForVariables = (varNames: string[]): Set<LeadFilter> => {
    const filters: Set<LeadFilter> = new Set();
    for (const varName of varNames) {
        if (varName in FILTER_DEPS) {
            FILTER_DEPS[varName as PlaybookVariable].forEach((depFilter) => {
                filters.add(depFilter);
            });
        }
    }
    return filters;
};