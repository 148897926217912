import { jwtDecode } from 'jwt-decode';

/**
 * Expected fields of the JWT
 * TODO: add more fields (right now just the ones that we use)
 */
interface IAccessToken {
    /**
     * When the token expires (in seconds since epoch)
     */
    exp: number;
}

export function isJwtExpired(accessToken: string): boolean {
    const decodedToken = jwtDecode<IAccessToken>(accessToken);
    // valueOf gives ms
    const nowInSeconds = (new Date().valueOf()) / 1000;
    const isExpired = decodedToken.exp < nowInSeconds;
    console.debug(`JWT is expired: ${isExpired}`);
    return isExpired;
}

export const deleteSavedAccessToken = () => {
    window.localStorage.removeItem('access_token');
};

export const readSavedAccessToken = (): string | null => {
    const token = window.localStorage.getItem('access_token');
    if (token && isJwtExpired(token)) {
        // check for expiry here
        // if the token is expired, delete it and return null
        deleteSavedAccessToken();
        return null;
    } else {
        return token;
    }
};

export const deleteSavedRefreshToken = () => {
    window.localStorage.removeItem('refresh_token');
};

export const readSavedRefreshToken = (): string | null => {
    const token = window.localStorage.getItem('refresh_token');
    if (token && isJwtExpired(token)) {
        // check for expiry here
        // if the token is expired, delete it and return null
        deleteSavedRefreshToken();
        return null;
    } else {
        return token;
    }
};


export const resetHeight = () => {
    const elem = document.getElementById('root');
    if (elem) {
        elem.style.height = window.innerHeight + 'px';
    }
};

export class AssertionError extends Error {}

/**
 * See https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
 */
export function assert(condition: any, msg?: string): asserts condition {
    if (!condition) {
        throw new AssertionError(msg);
    }
}

export const isMobileUser = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};