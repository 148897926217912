import { useQuery, useQueryClient, } from 'react-query';

import type { ILead } from 'src/services/api/leads/types';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';

type IGetLeadsOptions = {
    disabled?: boolean;
    isArchived?: boolean;
    refetchInterval?: number;
};

export type IGetLeadsResponse = {
    leads: ILead[];
};

/**
 * Leads are guaranteed to be sorted by `inserted_at` in descending order.
 */
export const useLeads = (options?: IGetLeadsOptions) => {
    const queryClient = useQueryClient();

    return useQuery(
        [queryKeys.leads],
        async () => {
            const searchParams = new URLSearchParams();
            if (options?.isArchived) {
                searchParams.append('include_archived', 'true');
            }
            const res = await apiClient(GET_LEADS_PATH, {
                searchParams
            }).json<IGetLeadsResponse>();

            return res.leads;
        }, {
            enabled: !options?.disabled,
            keepPreviousData: true,
            refetchInterval: options?.refetchInterval,
            onSuccess: (data) => {
                for (const lead of data) {
                    queryClient.setQueryData([queryKeys.leads, lead.uuid], lead);
                }
            }
        }
    );
};
