import React, { forwardRef } from 'react';

import Flex from 'src/components/flex/Flex';

type Props = Omit<React.ComponentProps<typeof Flex>, 'flexDirection'>;

const FlexColumn = forwardRef(function FlexColumn(
    { children, ...props}: Props,
    ref
) {
    return (
        <Flex ref={ref} flexDirection="column" {...props}>
            {children}
        </Flex>
    );
});

export default FlexColumn;
