import dayjs from 'dayjs';

import { MaintenanceWindow } from 'src/common/maintenance-window';

if (process.env.NODE_ENV !== 'test') {
    if (!import.meta.env['VITE_APP_API_SERVER']) {
        throw new Error('This variable must be set: VITE_APP_API_SERVER');
    }
}

// set the upcoming maintenance window here by reading environment vars
let UPCOMING_MAINTENANCE_WINDOW: MaintenanceWindow | null = null;
if (import.meta.env['VITE_APP_MAINTENANCE_WINDOW_START']) {
    const startTime = import.meta.env['VITE_APP_MAINTENANCE_WINDOW_START'];
    const durationMinutes = import.meta.env['VITE_APP_MAINTENANCE_WINDOW_DURATION_MINUTES'] ? parseInt(import.meta.env['VITE_APP_MAINTENANCE_WINDOW_DURATION_MINUTES']) : undefined;
    UPCOMING_MAINTENANCE_WINDOW = new MaintenanceWindow(
        dayjs(startTime),
        durationMinutes
    );
}

/**
 * Miscellaneous configuration parameters here
 */
export const Config = {
    isLocal: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost',

    /**
     * Split backend and frontend
     */
    useSeparateBackendServer: true,

    /**
     * The backend server to which to submit information
     */
    backendServer: import.meta.env['VITE_APP_API_SERVER'],

    /**
     * Maintenance window, which may or may not be in the future
     */
    maintenanceWindow: UPCOMING_MAINTENANCE_WINDOW,
};