import React, { useState } from 'react';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';
import Alert, { type AlertProps } from '@mui/material/Alert';

interface Props {
    severity: AlertProps['severity'];
    children: React.ReactNode;
}

const Notification: React.FC<Props> = ({ severity, children }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Portal>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert severity={severity}>
                    {children}
                </Alert>
            </Snackbar>
        </Portal>
    );
};

export default Notification;