import React from 'react';
import { Navigate } from 'react-router-dom';

import Auth from 'src/services/api/auth/Auth';
import { Config } from 'src/config';

type Props = {
  children: React.ReactNode;
  redirectTo: string;
};

/**
 * Any route that requires authentication
 */
export default function ProtectedRoute({ redirectTo, children }: Props) {
    const accessToken = Auth.accessToken;

    if (!accessToken) {
        return <Navigate to={redirectTo} replace />;
    }

    // when under maintenance, redirect to the home page (do not allow access to protected pages)
    if (Config.maintenanceWindow?.isActive()) {
        Auth.logout();
    }

    return <>{children}</>;
}