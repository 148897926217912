import { createContext } from 'react';

import { type NotificationType } from 'src/notifications/types';

export type AddNotificationFunction = (
  message: NotificationType['message'],
  variant: NotificationType['variant']
) => void;

interface NotificationContextType {
  addNotification: AddNotificationFunction
}

// @ts-expect-error lateinit
export const NotificationContext = createContext<NotificationContextType>();
