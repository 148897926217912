import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { analyticsClient } from 'src/services/analytics/analytics-client';

export const useTrackPageViews = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        analyticsClient.page();
    }, [pathname, analyticsClient]);
};