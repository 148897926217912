import { deleteSavedAccessToken } from 'src/common/utils';

/**
 * Handles client-side logout (for landlord views only)
 * - delete any access tokens from local storage
 * - redirect to login page, with appropriate redirect after logging back in
 * @param nextPath - path to redirect to after logging back in
 *                      if not provided, will not redirect after login
 */
export const redirectToLandlordLogin = (nextPath?: string) => {
    deleteSavedAccessToken();
    if (nextPath) {
        window.location.href = `/landlord/login?next_path=${nextPath}`;
    } else {
        window.location.href = '/landlord/login';
    }
};

/**
 * Version 2 of the redirect to landlord login
 * The updated version adds query parameters as well
 */
export const redirectToLandlordLoginV2 = () => {
    deleteSavedAccessToken();
    const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
    window.location.href = `/landlord/login?next_path=${currentPath}`;
};

export const redirectToPreviousPage = () => {
    window.history.back();
};
