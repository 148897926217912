import ky from 'ky';

import type { EMessageChannel } from 'src/services/api';
import { Config } from 'src/config';
import { createBearerHeader, type IStandardResponse } from 'src/common/api-utils';

/**
 * Some actions require additional user-specified parameters
 */
export interface IPlaybookActionParams {
    channel?: EMessageChannel;
}

/**
 * An existing playbook
 * Mirrors LandlordAutomation in models.py
 */
export interface IPlaybook {
    /**
     * This will exist so long as the playbook has been added to the DB
     */
    uuid: string;
    /**
     * This refers to the owner uniquely.
     * When encountered here, should be this user's ID.
     */
    landlord_user_id: number;
    /**
     * Title of playbook
     */
    title?: string | null;
    /**
     * The raw text of the playbook
     */
    text: string;
    /**
     * Whether this playbook runs in the background (requires a trigger)
     * Or if the user has to manually trigger it
     */
    is_background: boolean;
    /**
     * Trigger for the background playbook (undefined for foreground playbooks)
     */
    background_trigger?: BackgroundTrigger;
    /**
     * The text template for the playbook (if any)
     * If there's no text template then it's undefined
     */
    text_template?: string;
    /**
     * Whether this is a copy of an playbook template
     */
    is_cloned_from_template: boolean;
    /**
     * If this is a copy of an playbook template
     * Then this is the playbook/playbook template's UUID
     */
    cloned_template_uuid: string | null;
    /**
     * Whether the playbook is currently enabled
     */
    is_enabled: boolean;
    /**
     * TODO right now UTC timestamp
     */
    created_at: string;
    /**
     * Primary action for the playbook as defined in PlaybookAction
     * For legacy reasons it can be null
     */
    primary_action: string | null;
    primary_action_params: IPlaybookActionParams | null;
    /**
     * Filters applied to this playbook
     * Can be null for legacy reasons
     */
    lead_filters: string[] | null;
    lead_filter_params: IPlaybookTemplateParams | null;
    /**
     * Priority for the order in which the playbooks are applied
     */
    priority: number;

    secondary_actions: string[];

    latest_run?: PlaybookRun;
}

export enum PlaybookRunStatus {
    // automation has been queued for a run but has not been picked up by a worker
    QUEUED = 'queued',
    // automation has been picked up by a worker and is being executed
    RUNNING = 'running',
    // automation has completed running and did not suffer catastrophic errors
    // it may have suffered some errors along the way but it was able to complete
    COMPLETED = 'completed',
    //automation failed catastrophically and was not able to complete
    FAILED = 'failed',
}

export interface PlaybookRun {
    id: number;
    num_rows_affected?: number;
    status: PlaybookRunStatus;
    // we do not use date obj so we do not need to convert, timezone must be present inside the string
    stop_time: string;
    start_time: string;
}

/**
 * Currently supported primary playbook actions
 * Should match exactly with AutomationAction enum in automation_utils.py
 */
export enum PlaybookAction {
    SendTextMessageResponse = 'send_text_message_response',
    SendTextMessage = 'send_text_message',
    SendEmail = 'send_email',
    SendEmailResponse = 'send_email_response',
    /**
     * Send a message to the lead over a user-specified channel
     * Fall back to another channel if this channel is not available
     */
    SendMessageResponse = 'send_message_response',

    // these are valid secondary actions
    CreateRentalApplication = 'create_rental_application',
}

/**
 * Currently supported background triggers
 * Should match exactly with BackgroundTrigger enum in automations_utils.py
 */
export enum BackgroundTrigger {
    IncomingSms = 'incoming-sms',
    IncomingEmail = 'incoming-email',
    IncomingFacebookMessage = 'incoming-facebook-message',
    IncomingEmailFromListingSite = 'incoming-email-from-listing-site',
    TimeBasedSchedule = 'time-based-schedule',
}

/**
 * Currently supported lead filters
 * Should match exactly with LeadFilter enum in automations_utils.py
 * Make sure to keep these files in sync, otherwise weird things may happen
 */
export enum LeadFilter {
    ValidPhoneNumber = 'valid-phone-number',
    ValidEmail = 'valid-email',
    NoMessageHistory = 'no-message-history',
    IsLastEmailFromStreetEasy = 'is-last-email-from-street-easy',
    IsLastMessageFromLandlord = 'is-last-message-from-landlord',
    IsLastMessageFromLead = 'is-last-mesage-from-lead',
    IsLastMessageFromListingSite = 'is-last-message-from-listing-site',
    /**
     * There is only a single extant message in the thread
     * NOTE: the spirit of this filter is using it in conjuction with a background playbook
     * It's not really meant for foreground playbooks
     */
    IsFirstMessageInThread = 'is-first-message-in-thread',
    IsNewLead = 'is-new-lead',
    IsLeadSourceListingSite = 'is-lead-source-listing-site',
    IsIngestionSourceZillow = 'ingestion-source-zillow',
    LastMessageSentOver2DaysAgo = 'last-message-sent-over-2-days-ago',
    NoLandlordResponse = 'no-landlord-response',
    NoRentalApplicationInvite = 'no-rental-application-invite',
    IsLastMessageApplicationRequest = 'is-last-message-application-request',
    /**
     * Whether this lead is associated with a property or no
     */
    HasProperty = 'has-property',
    /**
     * Whether a lead is associated with a specific property
     */
    SpecificProperty = 'specific-property',
    /**
     * This lead has no showings scheduled
     */
    NoShowingsBooked = 'no-showings-booked',
    /**
     * The property that the lead was matched to has a property application link
     */
    HasPropertyApplicationLink = 'has-property-application-link',
    /**
     * The property that the lead was matched to has a property tour link
     */
    HasPropertyTourLink = 'has-property-tour-link',
    LastMessageFromLandlordWasSpecificTimeAgo = 'last-message-from-landlord-was-specific-time-ago',
    ShowingScheduled = 'showing-scheduled',
}

/**
 * Should mirror AutomationTemplateSchema in landlord_automations_api.py
 */
export interface IPlaybookTemplate {
    /**
     * Unique way to address this template
     */
    uuid: string;

    is_background: boolean;
    /**
     * Only set if the playbook is running in the background
     * i.e. is_background is true
     */
    background_trigger?: BackgroundTrigger;
    /**
     * The user-facing text describing the playbook
     */
    text: string;
    /**
     * What this playbook actually *does*
     */
    primary_action: PlaybookAction;

    /**
     * Whether the user needs to specify parameters for the primary action
     * At what they are
     * TODO specify type more specifically
     */
    required_primary_action_params?: string[];

    /**
     * Actions in addition to the above
     * This is optional
     */
    secondary_actions?: PlaybookAction[];
    /**
     * Whether that action requires a text template
     * This refers to whether the *user* has to specify a text template
     * The AutomationTemplate may specify a default even if this is false
     */
    requires_text_template: boolean;
    /**
     * What leads will be selected
     */
    lead_filters: LeadFilter[];
    /**
     * When specified, this is the default text for the playbook
     */
    text_template?: string;

    /**
     * Whether this playbook requires a specific property to be selected
     */
    requires_property_selection: boolean;

    /**
     * When specified, set the priority of this playbook
     * Should be an integer
     * Default priority is 1
     */
    priority?: number;

    /**
     * This is a UX feature for search and categorization
     * They are not meaningful on the backend
     */
    tags?: string[];
}

/**
 * Parameters passed to playbook templates
 * These are usually passed from some other view
 */
export interface IPlaybookTemplateParams {
    property_id?: number;

    // time-based playbooks parameters
    time_based_context?: 'before' | 'after';
    time_based_minutes?: number;
}

/**
 * A new playbook to be saved
 * Should mirror NewAutomationSchema in landlord_automations_api.py exactly
 */
export interface INewPlaybook {
    /**
     * The raw text of the playbook
     */
    text: string;
    /**
     * Title of playbook
     */
    title?: string | null;
    /**
     * Whether this playbook runs in the background (requires a trigger)
     * Or if the user has to manually trigger it
     */
    is_background: boolean;
    /**
     * Trigger for the background playbook (undefined for foreground playbooks)
     */
    background_trigger?: BackgroundTrigger;
    /**
     * Whether this playbook requires a text template specified by the user to run
     */
    requires_text_template: boolean;
    /**
     * Only required if requires_text_template is true
     * This text template is supplied by the user or copied over from the template
     */
    text_template?: string;
    /**
     * Whether this is a copy of a playbook template
     */
    is_cloned_from_template: boolean;
    /**
     * Only required if is_cloned_from_template is true
     */
    cloned_automation_template?: IPlaybookTemplate;
    /**
     * When specified, set the priority of the playbook
     * Default priority is 1
     */
    priority?: number;
    /**
     * may not be specified (not required)
     * additional parameters for the primary action
     */
    primary_action_params?: IPlaybookActionParams;


    /**
     * may not be specified (not required)
     * additional parameters for the lead filters
     * right now the only supported parameter is property_id
     */
    lead_filter_params?: IPlaybookTemplateParams;
}

export interface ICreatePlaybookResponse {
    status: string;
    msg: string;
    uuid: string;
}

export async function deletePlaybook(accessToken: string, uuid: string) {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    // TODO Change route after backend change
    url.pathname = `/api/landlord/automations/${uuid}`;
    const headers = createBearerHeader(accessToken);
    return ky.delete(url.toString(), {
        headers: headers,
    }).json() as Promise<IStandardResponse>;
}

export interface IEligibleRowsResponse {
    num_eligible_rows: number;
    eligible_lead_uuids: string[];
}

export interface IEligibleRowsOptions {
    uuid: string;
    // automation_template?: IAutomationTemplate;
    // automation_template_params?: IAutomationTemplateParams;
}

/**
 * Get the number of eligible rows for an automation
 * It handles 2 cases:
 * 1. Background/recurring automations: specify the uuid
 * 2. Foreground/manual automations: specify the entire automation template with params
 */
export async function getNumEligibleRows(accessToken: string, options: IEligibleRowsOptions): Promise<IEligibleRowsResponse> {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/automations/num-eligible-rows';
    url.searchParams.set('uuid', options.uuid);
    // const data = Object.assign({}, options);
    // if (!data.uuid && !data.automation_template) {
    //     throw new Error('must specify either uuid or automation template');
    // }
    const headers = createBearerHeader(accessToken);
    return ky.get(url.toString(), {
        headers: headers,
    }).json() as Promise<IEligibleRowsResponse>;
}

export interface IPlaybookRunResponse {
    status: 'success';
    msg: string;
    num_errors: number;
    num_rows_affected: number;
    num_sms_sent: number;
}

/**
 * Trigger an *existing* automation that has been saved.
 *
 * Uses ky
 * NOTE: by default this function will have a longer timeout period than others
 * Make sure to catch the timeout error as it may happen
 * See: https://github.com/sindresorhus/ky
 * @throws {HTTPError}
 */
export async function trigger(accessToken: string, uuid: string): Promise<IPlaybookRunResponse> {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/automations/trigger';
    const data = {
        uuid: uuid,
    };
    const headers = createBearerHeader(accessToken);
    return ky.post(url.toString(), {
        headers: headers,
        json: data,
        // NOTE: specify a timeout of 25 seconds
        timeout: 25000,
    }).json() as Promise<IPlaybookRunResponse>;
}

/**
 * Run a manual automation based on a customized template
 */
export async function run(accessToken: string, template: IPlaybookTemplate, params?: IPlaybookTemplateParams): Promise<IPlaybookRunResponse> {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/automations/run';
    const data = {
        template: template,
        params: params ? params : {},
    };
    const headers = createBearerHeader(accessToken);
    return ky.post(url.toString(), {
        headers: headers,
        json: data,
        // NOTE: specify a timeout of 25 seconds
        timeout: 25000,
    }).json() as Promise<IPlaybookRunResponse>;
}

export interface IUpdatePlaybookInput {
    /**
     * Title of playbook
     */
    title?: string | null;
    /**
     * Update whether the playbook should be enabled or no
     */
    is_enabled?: boolean;
    /**
     * Update the text template associated with this playbook
     */
    text_template?: string;

    /**
     * The selected property ID (for lead filter params)
     * This is only relevant if the playbook requires a property selection
     */
    property_id?: number;
    /**
     * Update the primary action params
     */
    primary_action_params?: IPlaybookActionParams;
}

export async function updatePlaybook(accessToken: string, uuid: string, updateFields: IUpdatePlaybookInput) {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/automations/${uuid}`;
    const data = Object.assign({}, updateFields);
    const headers = createBearerHeader(accessToken);
    return ky.patch(url.toString(), {
        headers: headers,
        json: data,
    }).json() as Promise<IStandardResponse>;
}

export default {
    getNumEligibleRows,
    trigger,
    delete: deletePlaybook,
    update: updatePlaybook,
};
