/**
 * This file collects the API utilities so it's easier to make JSON requests
 */

import { type HTTPError } from 'ky';

import type { IStandardErrorResponse } from 'src/api/common';

export const createBearerHeader = (apiToken: string): {[key: string]: string} => {
    if (!apiToken) {
        // for safety
        throw new Error('error: empty API token given');
    }
    return {
        'Authorization': `Bearer ${apiToken}`,
    };
};

export class ApiError extends Error {
    /* status code */
    status: number;
    // help identify the type of error
    _type: string;
    errorCode?: string;

    constructor(msg: string, status: number, errorCode?: string) {
        super(msg);
        this.status = status;
        this._type = 'ApiError';
        this.errorCode = errorCode;
    }
}

/**
 * Extract error message from the response if it is provided
 * Return an ApiError in all cases
 * @param {string} defaultMessage If no message provided, throw ApiError with this message
 */
export const parseResponseError = async (r: Response, defaultMessage: string): Promise<ApiError> => {
    if (r.headers.get('Content-Type') === 'application/json') {
        const j = await r.json();
        if (j.msg && j.error_code) {
            return new ApiError(j.msg, r.status, j.error_code);
        } else if (j.msg) {
            return new ApiError(j.msg, r.status);
        } else {
            return new ApiError(defaultMessage, r.status);
        }
    } else {
        return new ApiError(defaultMessage, r.status);
    }
};

/**
 * Type guard for ky HTTPError
 * See https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
export function isHTTPError(err: unknown): err is HTTPError {
    return (err as HTTPError).name === 'HTTPError';
}

export function isStandardErrorResponse(err: unknown): err is IStandardErrorResponse {
    return (err as IStandardErrorResponse)?.msg !== undefined;
}

/**
 * A standard response from the API server for most requests
 */
export interface IStandardResponse {
    /**
     * In almost all cases the status is "success"
     */
    status: string;
    msg: string;
}
