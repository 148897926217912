import { useQuery } from 'react-query';

import type { IConversation } from 'src/api/landlord-messages-api';
import { apiClient, queryKeys } from 'src/services/api';

const PATH = 'api/landlord/conversations';

interface IGetConversationsOptions {
    disabled?: boolean;
    /**
     * true - return only archived conversations
     * false - return only unarchived conversations
     * undefined - return all conversations
     */
    isArchived?: boolean;
}

export type IGetConversationsResponse = {
    conversations: IConversation[];
}

export const useConversations = (options?: IGetConversationsOptions) => {
    return useQuery<IConversation[]>(
        [queryKeys.conversations],
        async () => {
            const searchParams = new URLSearchParams();
            if (options?.isArchived != null) {
                searchParams.append('is_archived', options.isArchived.toString());
            }

            const res = await apiClient(PATH, { searchParams }).json<IGetConversationsResponse>();
            return res.conversations;
        }, {
            enabled: !options?.disabled,
            keepPreviousData: true,
            staleTime: Infinity,
        }
    );
};
