import { type AnalyticsPlugin } from 'analytics';
import mixpanel from 'mixpanel-browser';

import type { AnalyticsEventParams, AnalyticsPageEventProperties } from 'src/services/analytics/plugins/types';

// Initialize Mixpanel
// If the project token is not set, we will disable Mixpanel
// We don't want to send events to Mixpanel from local development
if (import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN);
} else {
    mixpanel.init('NO_TOKEN');
    mixpanel.disable();
}

export const mixpanelPlugin: AnalyticsPlugin = {
    name: 'mixpanel-plugin',
    identify: ({ payload }: AnalyticsEventParams) => {
        mixpanel.identify(payload.userId);
    },
    page: ({ payload }: AnalyticsEventParams<AnalyticsPageEventProperties>) => {
        mixpanel.track('Page View', payload.properties);
    },
    track: ({ payload }: AnalyticsEventParams) => {
        mixpanel.track(payload.event, payload.properties);
    },
};