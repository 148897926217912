import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import type { IConversation } from 'src/api/landlord-messages-api';
import type { ILead, Notification } from 'src/services/api';
import { ThemeProvider } from 'src/theme';
import { Auth, useConversations, useLeads, useNotifications } from 'src/services/api';
import { FlexColumn } from 'src/components/flex';

type IAppState = {
    isHelpSidebarOpened: boolean;
    setHelpSidebarOpened: Action<boolean>;

    isTawkChatOpened: boolean;
    setTawkChatOpened: Action<boolean>;

    /**
     * If true, archived leads and conversations will be fetched.
     * Warning: The response size can get very large.
     */
    showArchived: boolean;
    setShowArchived: Action<boolean>;

    leads: ILead[];
    leadsByUuid: Map<string, ILead>;
    conversations: IConversation[];
    conversationsByLeadUuid: Map<string, IConversation>;

    notifications: Notification[];
};

// @ts-expect-error lazy init
export const AppStateContext = createContext<IAppState>();

export const useAppState = () => {
    const context = React.useContext(AppStateContext);

    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};

type Props = {
    children: React.ReactNode;
};

export default function AppStateProvider({ children }: Props) {
    const isLoggedIn = !!Auth.accessToken;

    const init = useRef(false);

    const [isHelpSidebarOpened, setHelpSidebarOpened] = useState(false);
    const [isTawkChatOpened, setTawkChatOpened] = useState(false);

    const [showArchived, setShowArchived] = useState(false);

    const { data: leads, refetch: refetchLeads } = useLeads({
        disabled: !isLoggedIn,
        isArchived: showArchived ? true : false,
        refetchInterval: 20000,
    });

    const leadsByUuid = useMemo(() => {
        if (!leads) { return new Map(); }

        return leads.reduce((acc, lead) => {
            acc.set(lead.uuid, lead);
            return acc;
        }, new Map<string, ILead>());
    }, [leads]);

    const { data: conversations, refetch: refetchConversations } = useConversations({
        disabled: !isLoggedIn,
        isArchived: showArchived ? undefined : false,
    });

    const conversationsByLeadUuid = useMemo(() => {
        if (!conversations) { return new Map(); }

        return conversations.reduce((acc, conversation) => {
            acc.set(conversation.lead_uuid, conversation);
            return acc;
        }, new Map<string, IConversation>());
    }, [conversations]);

    // Refetch leads and conversations when showArchived changes
    useEffect(() => {
        if (!Auth.accessToken) { return; }

        if (!init.current) {
            init.current = true;
            return;
        }

        refetchLeads();
        refetchConversations();
    }, [showArchived]);

    const { data: notifications } = useNotifications(!isLoggedIn);

    const isLoading = isLoggedIn && (!notifications || !leads || !conversations);

    return (
        <AppStateContext.Provider
            value={{
                isHelpSidebarOpened,
                setHelpSidebarOpened,

                isTawkChatOpened,
                setTawkChatOpened,

                showArchived,
                setShowArchived,

                leads: leads || [],
                leadsByUuid,
                conversations: conversations || [],
                conversationsByLeadUuid,

                notifications: notifications || [],
            }}
        >
            {isLoading ? (
                <ThemeProvider>
                    <FlexColumn
                        minHeight="100vh"
                        bgcolor="var(--background-color-beige)"
                        rowGap={0}
                    >
                        <LinearProgress />
                    </FlexColumn>
                </ThemeProvider>
            ) : (
                children
            )}
        </AppStateContext.Provider>
    );
}

type Action<T> = React.Dispatch<React.SetStateAction<T>>;
