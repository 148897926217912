import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import pluralize from 'pluralize';

import { ENotificationType, type NotificationBase } from 'src/services/api/notifications/types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

export type MaintenanceWindowNotification = NotificationBase &{
    // fill in some fields
    id: 0;
    is_read_by_user: false;
    is_informational: true;
    is_shared_with_team: false;
    type: ENotificationType.UpcomingMaintenanceWindow;

    // extra field
    payload: {
        maintenanceWindow: MaintenanceWindow;
    }
};

export class MaintenanceWindow {
    startTime: Date;
    endTime: Date;
    durationMinutes: number;

    constructor(startTime: Date | dayjs.Dayjs, durationMinutes?: number) {
        if (startTime instanceof dayjs) {
            this.startTime = (startTime as dayjs.Dayjs).toDate();
        } else {
            this.startTime = (startTime as Date);
        }

        if (!durationMinutes) {
            durationMinutes = 30;
        }
        this.durationMinutes = durationMinutes;

        this.endTime = new Date(this.startTime.getTime());
        this.endTime.setMinutes(this.endTime.getMinutes() + durationMinutes);
    }

    isActive(): boolean {
        const now = new Date();
        return now >= this.startTime && now <= this.endTime;
    }

    isUpcoming(): boolean {
        const now = new Date();
        return now < this.startTime;
    }

    /**
     * Localize the start time to the current user's timezone
     * i.e. the timezone of the browser running the code
     */
    getLocalizedStartTime(): string {
        const userTz = dayjs.tz.guess();
        const localStartTime = dayjs(this.startTime).tz(userTz);
        return localStartTime.format('L LT');
    }

    /**
     * Localize the start time to the current user's timezone
     * i.e. the timezone of the browser running the code
     */
    getLocalizedEndTime(): string {
        const userTz = dayjs.tz.guess();
        const localStartTime = dayjs(this.endTime).tz(userTz);
        return localStartTime.format('L LT z');
    }

    getAlertTitle(): string {
        if (this.isUpcoming()) {
            const hours = this.durationMinutes / 60.0;
            const duration = (hours >= 1.0 ? `${Math.ceil(hours)} ${pluralize('Hour', hours)}` : `${this.durationMinutes} Minutes`);

            return `Scheduled Maintenance for ${duration}`;
        } else {
            return '';
        }
    }

    toNotification(): MaintenanceWindowNotification {
        const now = new Date();
        const interval = dayjs(this.startTime).fromNow();

        return {
            id: 0,
            is_read_by_user: false,
            is_informational: true,
            is_shared_with_team: false,
            type: ENotificationType.UpcomingMaintenanceWindow,

            inserted_at: now.toISOString(),
            modified_at: now.toISOString(),

            text: `Reffie is scheduled for maintenance ${interval}. Please plan accordingly.`,

            payload: {
                maintenanceWindow: this
            }
        };
    }
}
