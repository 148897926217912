import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const LandlordLogin = lazy(() => import('src/pages/landlord/login/LandlordLogin'));
const LandlordPasswordResetStart = lazy(() => import('src/pages/landlord/password-reset/LandlordPasswordResetStart'));
const LandlordPasswordResetConfirm = lazy(() => import('src/pages/landlord/password-reset/LandlordPasswordResetConfirm'));

export const landlordAuthRoutes = [
    <Route key="/landlord/login" path="/landlord/login" element={<LandlordLogin />} />,
    <Route key="/landlord/password-reset/start" path="/landlord/password-reset/start" element={<LandlordPasswordResetStart />} />,
    <Route key="/landlord/password-reset/confirm" path="/landlord/password-reset/confirm" element={<LandlordPasswordResetConfirm />} />,
];