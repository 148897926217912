
import { useQuery } from 'react-query';

import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';
import type { UnmatchedListingNotification } from 'src/services/api/notifications/types';
import type { MaintenanceWindowNotification } from 'src/common/maintenance-window';
import { ENotificationType } from 'src/services/api/notifications/types';
import { Config } from 'src/config';

const PATH = 'api/landlord/notifications';

export const isUnmatchedListingNotification = (n: Notification): n is UnmatchedListingNotification => {
    return n.type === ENotificationType.FailedToMatchListingToProperty;
};

export const isFacebookIntegrationNotification = (n: Notification): boolean => {
    return n.type == ENotificationType.FailedFacebookIntegration;
};

export const isUpcomingMaintenanceWindowNotification = (n: Notification): n is MaintenanceWindowNotification => {
    return n.type == ENotificationType.UpcomingMaintenanceWindow;
};

export type Notification = UnmatchedListingNotification | MaintenanceWindowNotification;

type Response = {
    notifications: Notification[];
};

export const useNotifications = (disabled?: boolean) => {
    return useQuery(
        [queryKeys.notifications],
        async () => {
            const response = await apiClient(PATH).json<Response>();
            const serverNotifications = response.notifications;

            const localNotifications: Notification[] = [];
            if (Config.maintenanceWindow?.isUpcoming()) {
                localNotifications.push(Config.maintenanceWindow.toNotification());
            }

            return serverNotifications.concat(localNotifications);
        }, {
            enabled: !disabled,
        }
    );
};
