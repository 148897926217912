import ky from 'ky';

import { Config } from 'src/config';

const prefixUrl = Config.backendServer;

/**
 * Bare minimum setup needed to send a request to our backend server.
 */
export const simpleKy = ky.create({
    prefixUrl,
});
