export enum ENotificationType {
    FailedToMatchListingToProperty = 'failed_to_match_listing_to_property',
    FailedFacebookIntegration = 'facebook_integration_error',
    UpcomingMaintenanceWindow = 'upcoming_maintenance_window',
}

export type NotificationBase = {
    id: number;
    text: string;
    type: ENotificationType;
    is_read_by_user: boolean;
    is_informational: boolean;
    is_shared_with_team: boolean;
    inserted_at: string;
    modified_at: string;
};

export type UnmatchedListingNotification = NotificationBase & {
    payload: {
        name: string;
        ingestion_source: string;
        ingestion_method: string;
        leasing_team_id: number;
        num_leads: number;
        image_url?: string;
    }
};
