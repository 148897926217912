export enum AnalyticsEvent {
    REDITECTED_FROM_BETA_TO_PLATFORM = 'Redirected from beta to platform',

    SMART_REPLIES_VIEWED = 'Smart replies viewed',
    SMART_REPLY_SELECTED = 'Smart reply selected',

    ARCHIVED_CONVERSATIONS_VIEWED = 'Archived conversations viewed',

    NOTIFICATION_ALERT_DISMISSED = 'Notification alert dismissed',
    NOTIFICATION_REQUEST_DENIED = 'Notification request denied',
    NOTIFICATION_REQUEST_GRANTED = 'Notification request granted',

    SHORTCUT_KEY_USED = 'Shortcut key used',
    BUTTON_CLICKED = 'Button clicked',
}