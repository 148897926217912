import useMediaQuery from '@mui/material/useMediaQuery';
import baseTheme from 'src/theme/base-theme';

export const mobile = baseTheme.breakpoints.down('md');
export const desktop = baseTheme.breakpoints.up('md');

export const useMobile = () => {
    return useMediaQuery(mobile);
};

export const useDesktop = () => {
    return useMediaQuery(desktop);
};