import { useQuery } from 'react-query';

import Auth from 'src/services/api/auth/Auth';
import { queryKeys } from 'src/services/api/constants';

export const useLoggedInUser = (disabled?: boolean) => {
    return useQuery(
        [queryKeys.loggedInUser],
        Auth.getLoggedInUser,
        { enabled: !disabled, retry: false, refetchOnWindowFocus: false }
    );
};
