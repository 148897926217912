import React, { forwardRef } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';

import { useMobile } from 'src/theme/responsive';

export type FlexProps = Omit<BoxProps, 'display'> & {
    mobileFlexDirection?: 'row' | 'column';
}

const Flex = forwardRef(function Flex(
    props: FlexProps,
    ref
) {
    const {
        flexDirection: propsFlexDirection,
        mobileFlexDirection,
        children,
        ...restProps
    } = props;

    const isMobile = useMobile();

    let flexDirection = propsFlexDirection;
    if (isMobile && mobileFlexDirection) {
        flexDirection = mobileFlexDirection;
    }

    return (
        <Box
            ref={ref}
            display="flex"
            rowGap={1}
            columnGap={1}
            {...restProps}
            flexDirection={flexDirection}
        >
            {children}
        </Box>
    );
});

export default Flex;
