export enum ELeadRoutingRuleType {
  DEFAULT = 'default',
  PROPERTIES = 'properties',
  PROPERTY_LABELS = 'property_labels',
  LEAD_SOURCES = 'lead_sources'
}

export const leadRoutingRuleLabels = {
    [ELeadRoutingRuleType.DEFAULT]: 'No other rules match',
    [ELeadRoutingRuleType.PROPERTIES]: 'Property',
    [ELeadRoutingRuleType.PROPERTY_LABELS]: 'Property label',
    [ELeadRoutingRuleType.LEAD_SOURCES]: 'Lead source'
};

export const leadRoutingRuleTypeOptions = Object.values(ELeadRoutingRuleType).map((type) => ({
    value: type,
    label: leadRoutingRuleLabels[type]
}));

interface ILeadRoutingRuleBase {
  id: number;
  leasing_team_id: number;
  target_landlord_user_id: number;
  type: ELeadRoutingRuleType;
  user_assigned_order: number;
  created_at: string;
  modified_at: string;
}

export interface ILeadRoutingRuleDefault extends ILeadRoutingRuleBase {
  type: ELeadRoutingRuleType.DEFAULT;
}

export interface ILeadRoutingRuleProperties extends ILeadRoutingRuleBase {
  type: ELeadRoutingRuleType.PROPERTIES;
  params: {
    property_ids: number[];
  };
}

export interface ILeadRoutingRulePropertyLabels extends ILeadRoutingRuleBase {
  type: ELeadRoutingRuleType.PROPERTY_LABELS;
  params: {
    property_label_ids: number[];
  };
}

export interface ILeadRoutingRuleListingSiteNames extends ILeadRoutingRuleBase {
  type: ELeadRoutingRuleType.LEAD_SOURCES;
  params: {
    lead_sources: string[];
  };
}

export type ILeadRoutingRule =
  | ILeadRoutingRuleDefault
  | ILeadRoutingRuleProperties
  | ILeadRoutingRulePropertyLabels
  | ILeadRoutingRuleListingSiteNames;
