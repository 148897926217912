import React from 'react';

import Auth from 'src/services/api/auth/Auth';
import ProtectedRoute from 'src/components/auth/ProtectedRoute';

type Props = {
  children: React.ReactNode;
};

export default function LandlordProtectedRoute({ children }: Props) {
    return (
        <ProtectedRoute redirectTo={Auth.landlordLoginPath}>
            {children}
        </ProtectedRoute>
    );
}