import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

import { redirectToLandlordLoginV2 } from 'src/api';
import type { IUser } from 'src/api/users-api';
import type { IStandardResponse } from 'src/common/api-utils';
import { simpleKy } from 'src/services/api/ky/simple-ky';

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  user: IUser;
}

export type SendPasswordResetEmailResponse = {
    status: string;
    msg: string;
}

export type ChangePasswordResponse = IStandardResponse;

export default class Auth {
    static landlordLoginPath = '/landlord/login';

    static get accessToken(): string | undefined {
        return window.localStorage.getItem('access_token') || undefined;
    }

    static set accessToken(token: string | undefined) {
        if (token === undefined) {
            window.localStorage.removeItem('access_token');
        } else {
            window.localStorage.setItem('access_token', token);
        }
    }

    static get refreshToken(): string | undefined {
        return window.localStorage.getItem('refresh_token') || undefined;
    }

    static set refreshToken(token: string | undefined) {
        if (token === undefined) {
            window.localStorage.removeItem('refresh_token');
        } else {
            window.localStorage.setItem('refresh_token', token);
        }
    }

    static getAuthHeaders(token?: string): HeadersInit | undefined {
        if (!token) {
            return;
        }

        return {
            Authorization: `Bearer ${token}`,
        };
    }

    static isJwtExpired(token: string): boolean {
        const payload = jwtDecode(token);
        const now = dayjs();
        const isExpired = !!payload.exp && now.isAfter(dayjs.unix(payload.exp));

        return isExpired;
    }

    static redirectToLoginPage() {
        Auth.accessToken = undefined;
        redirectToLandlordLoginV2();
    }

    static async refreshAccessToken(): Promise<void> {
        const refreshToken = Auth.refreshToken;

        if (!refreshToken) {
            Auth.redirectToLoginPage();
            return;
        }

        const headers = Auth.getAuthHeaders(refreshToken);

        try {
            const _res = await simpleKy(
                'api/auth/landlord/refresh',
                { method: 'post', headers }
            );

            if (_res.ok) {
                const res = await _res.json<Pick<LoginResponse, 'access_token'>>();
                Auth.accessToken = res.access_token;
            } else {
                Auth.redirectToLoginPage();
            }
        } catch (err) {
            console.error('Failed to refresh access token', err);
        }
    }

    static async login(email: string, password: string) {
        const res = await simpleKy('api/auth/landlord/login', {
            method: 'post',
            json: { email, password },
        }).json<LoginResponse>();

        Auth.accessToken = res.access_token;
        Auth.refreshToken = res.refresh_token;

        window.localStorage.setItem('access_token', res.access_token);
        window.localStorage.setItem('refresh_token', res.refresh_token);

        return res;
    }

    static logoutWithoutRedirect() {
        Auth.accessToken = undefined;
        Auth.refreshToken = undefined;
    }

    static logout() {
        Auth.logoutWithoutRedirect();
        redirectToLandlordLoginV2();
    }

    static async getLoggedInUser() {
        if (!Auth.accessToken) {
            return undefined;
        }

        const res = await simpleKy('api/users/me', {
            headers: Auth.getAuthHeaders(Auth.accessToken),
        }).json<IUser>();

        return res;
    }

    static async getLoggedInUserWithIngestEmailUsage() {
        if (!Auth.accessToken) {
            return undefined;
        }

        const res = await simpleKy('api/users/me?include_ingest_email_usage=true', {
            headers: Auth.getAuthHeaders(Auth.accessToken),
        }).json<IUser>();

        return res;
    }

    static sendPasswordResetEmail(email: string) {
        return simpleKy('api/users/me/password-reset/send-email', {
            method: 'post',
            json: { email },
        }).json<SendPasswordResetEmailResponse>();
    }

    static async changePassword(token: string, newPassword: string) {
        return simpleKy('api/users/me/password-reset/confirm', {
            method: 'post',
            json: { token, new_password: newPassword },
        }).json<ChangePasswordResponse>();
    }
}
